#video-call-div {
  position: absolute;
  top: 7%;
  left: 0;
  width: 100%;
  height: 90%;
  /* display: none;  for sender.js */
  display: none; /*  for call.js block*/
}

#local-video {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
  border-radius: 16px;
  max-width: 20%;
  max-height: 20%;
  background: #ffffff;
}

#remote-video {
  background: #000000;
  width: 100%;
  height: 100%;
}

.video-action-div {
  position: absolute;
  left: 20%;
  bottom: 32px;
  width: 76%;
  display: flex;
  justify-content: center;
}

button {
  cursor: pointer;
  /* border-radius: 50; */
  margin: 5px;
}

.main {
  display: flex;
  align-items: flex-end;
  gap: 3rem;
}
.main__right {
  display: none;
  flex-direction: column;
  background-color: #242324;
  border-left: 1px solid #3d3d42;
}
.main__right.active {
  display: flex;
}
.main__videos {
  flex-grow: 1;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main__controls {
  display: flex;
  background-color: #1c1e20;
  color: #d2d2d2;
  padding: 5px;
  justify-content: space-between;
}
.main__header {
  color: #f5f5f5;
  text-align: center;
  padding: 20px;
  border-bottom: 2px solid #3d3d42;
}
.main__message_container {
  display: flex;
  justify-content: center;
}
.main__message_container textarea {
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 18px;
  outline: none;
  border-radius: 20px;
}

.sendMsgBtn {
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  background-color: #008000;
  border-radius: 20px;
  padding-left: 5px;
  margin-left: 5px;
}

#audio-call-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

#local-audio {
  position: absolute;
}

#remote-audio {
  background: #000000;
  width: 100%;
  height: 100%;
}

.call-action-div {
  position: absolute;
  display: flex;
  left: 45%;
  bottom: 30%;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

#chat-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  display: none;
}
#chat-animate-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  background-color: #4b4e50;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.connecting_div {
  color: #fff;
  margin-top: 50px;
}

#local-chat {
  position: absolute;
}

#remote-chat {
  background: #ffffff;
  width: 100%;
  height: 100%;
}

.chat-action-div {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  bottom: 5px;
  width: 100%;
  padding: 10px;
}

.send_btn {
  border-color: #ffb33e !important;
  background-color: #ffffff !important;
  color: black !important;
  cursor: pointer;
  margin: 5px;
}

.send_btn:hover {
  font-weight: bold !important;
}

.chatBox {
  margin: 10px;
  margin-bottom: 15px;
  padding: 10px;
  background: rgb(255, 216, 43);
  width: 100%;
  height: 73vh;
  border: 1px solid #a7a7a7;
  overflow: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.videochatBox {
  background: rgb(255, 216, 43);
  padding: 10px;
  width: 100%;
  height: 65vh;
  border: 1px solid #a7a7a7;
  overflow: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#chatarea {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: auto;
}
.message {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 60%;
}

.my-message {
  background-color: #f0f0f0;
  align-self: flex-end; /* Align "Me" messages to the right */
  text-align: left;
}

.remote-message {
  background-color: #d1e7dd;
  align-self: flex-start; /* Align "Remote" messages to the left */
}

@media (min-width: 100px) and (max-width: 319px) {
  #video-call-div {
    top: 20%;
    height: 78%;
  }
  .video-action-div {
    left: 6%;
    flex-direction: column-reverse;
  }
  .call-action-div {
    left: 35%;
  }
  .chat-action-div {
    flex-direction: column-reverse;
    bottom: 1px;
  }
  .message {
    max-width: 90%;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  #video-call-div {
    top: 20%;
    height: 78%;
  }
  .video-action-div {
    left: 6%;
    flex-direction: column-reverse;
  }
  .chat-action-div {
    flex-direction: column-reverse;
    bottom: 1px;
  }
  .message {
    max-width: 90%;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  #video-call-div {
    top: 20%;
    height: 78%;
  }
  .video-action-div {
    left: 10%;
    flex-direction: column-reverse;
  }
  .call-action-div {
    left: 38%;
  }
  .chat-action-div {
    flex-direction: column-reverse;
    bottom: 1px;
  }
  .message {
    max-width: 90%;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  #video-call-div {
    top: 12%;
    height: 86%;
  }
  .video-action-div {
    left: 15%;
  }
  .call-action-div {
    left: 43%;
  }
  .chat-action-div {
    bottom: 1px;
  }
  .message {
    max-width: 85%;
  }
}

@media (max-width: 640px) {
  .main_controls_button span {
    display: none;
  }
  .main__right {
    position: fixed;
    width: 100%;
    opacity: 0.8;
    margin-bottom: 46px;
    left: 0%;
    padding: 2%;
  }
  .videochatBox {
    height: 50vh;
  }
  .chatBox {
    height: 75vh;
  }
}
